import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import searchPng from "../img/search.png"
import '../style/resources.sass';

export const ResourcesPageTemplate = ({ heading, title, styles, dropdown, description, resources }) => {

  
  const arrow = getImage(dropdown)

  return (
    <>
      <section className="page-intro-section" style={{backgroundColor: "black", height: styles.sectionHeight}}>
        <div className="page-intro-content" style={{width: styles.contentWidth, padding: styles.contentPadding}}>
          <div>
            <p className="page-intro-section-heading" style={{fontSize: styles.descriptionFontSize, lineHeight: styles.descriptionLineHeight, fontWeight: styles.descriptionFontWeight}}>{heading}</p>
          </div>
          <div className="page-intro-title">
            <p style={{fontSize: styles.titleFontSize, lineHeight: styles.titleLineHeight, fontWeight: styles.titleFontWeight}}>{title}</p>
          </div>
          <div>
            <p className="page-intro-section-description" style={{fontSize: styles.descriptionFontSize, lineHeight: styles.descriptionLineHeight, fontWeight: styles.descriptionFontWeight}}>{description}</p>
          </div>
        </div>
      </section>

      <section className="section" style={{ padding: "5% 15% 5%" }} >
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-7">
              <div className="field has-addons has-addons-centered">
                <div className="control  is-expanded">
                <div className="control has-icons-left" style={{ position: 'relative', width: '100%' }}>
                  <input className="input is-small" type="text " placeholder=" Search"
                   style={{ width: '90%', color:'black', backgroundColor: '#D9D9D9', paddingLeft: '2rem' , height: '2rem' , borderRadius: '3px'}} />
                    <span className="icon is-small is-left" style={{ position: 'absolute', left: '0.1rem',color:'black', top: '50%', transform: 'translateY(-50%)' }}>
                    <img src={searchPng} alt="Github" style={{'height':'1rem'}}/>
                   </span>
                  </div>             
                </div>
                 
                  <div className="dropdown">
                    <div className="dropdown-sub">
                      <button>Categories {arrow ? (
                      <GatsbyImage image={arrow} alt='Loading...' className='dropdown-arrow'/>
                      ) : (
                      <img src={dropdown} alt='Loading...' className='dropdown-arrow'/>
                      )}</button>
                    </div>
                    
                    <div className="dropdown-content">
                        <label><input type="checkbox" name="categories" value="Blogs" className='custom-checkbox' /> Blogs</label>
                        <label><input type="checkbox" name="categories" value="White Papers" className='custom-checkbox' /> White Papers</label>
                        <label><input type="checkbox" name="categories" value="Case Studies" className='custom-checkbox' /> Case Studies</label>
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <div className="columns is-multiline">
            {resources.map((resource, index) => {
              const image = getImage(resource.image);
              return (
                <div className="column is-3" key={index}>
                  <div className="card">
                    <div className="card-image">
                      <figure className="image is-16by9" style={{paddingTop:'0'}}>
                        {image ? (
                          <GatsbyImage image={image} alt={resource.title} />
                        ) : (
                          <img src={resource.image} alt={resource.title} />
                        )}
                      </figure>
                    </div>
                    <div style={{height: 'auto' , padding:'2%'}}>
                      <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                            <p className="subtitle is-7" style={{fontSize: `clamp(0.5rem , 0.7vw , 0.912rem)` , lineHeight: '1.4001' , fontWeight: '400', color: '#000000'}}>{resource.category}</p>
                            <p className="subtitle is-7" style={{fontSize: `clamp(0.5rem , 0.7vw , 0.912rem)` , lineHeight: '1.4001' , fontWeight: '400', color: '#000000'}}>{resource.date}</p>
                        </div>
                      </div>
                      <div>
                        <p className="title is-7" style={{fontSize: `clamp(0.5rem , 1.1vw , 1.2rem)` , lineHeight: '1.219' , fontWeight: '700', color: '#000000' , width: '89%'}}>{resource.title}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="has-text-centered" style={{display: 'flex' , justifyContent: 'center'}}>
            <button className="conatiner-main-button">Load More</button>
          </div>
        </div>
      </section>
    </>
  );
};


const ResourcePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <ResourcesPageTemplate
        heading={frontmatter.heading}
        title={frontmatter.title}
        styles={frontmatter.styles}
        description={frontmatter.description}
        dropdown={frontmatter.dropdown}
        resources={frontmatter.resources}
      />
    </Layout>
  );
};

ResourcePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        heading: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        styles: PropTypes.object,
        dropdown: PropTypes.oneOfType([
          PropTypes.string, // For fallback if image is not processed by sharp
          PropTypes.object, // For sharp image data
        ]),
        resources: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.oneOfType([
              PropTypes.string, // For fallback if image is not processed by sharp
              PropTypes.object, // For sharp image data
            ]),
            category: PropTypes.string,
            date: PropTypes.string,
            title: PropTypes.string,
          })
        ),
      }),
    }),
  }),
};

export default ResourcePage;

export const resourcePageQuery = graphql`
  query ResourcePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        heading
        title
        description
        styles { 
          sectionHeight
          titleFontSize
          titleFontWeight
          titleLineHeight
          descriptionFontSize
          descriptionFontWeight
          descriptionLineHeight
          contentWidth
          contentPadding
        }
        dropdown {
          childImageSharp {
            gatsbyImageData(quality: 100)  # Adjust the layout and width as needed
          }
        }
        resources {
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 500)  # Adjust the layout and width as needed
            }
          }
          category
          date
          title
        }
      }
    }
  }
`;
